@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300&display=swap");

body {
  font-family: "Raleway", sans-serif;
  color: grey;
  width: 80%;
  margin: 0 auto;
}
.App {
  border: double #b9b6b6 5px;
  border-radius: 5px;
  margin: 50px auto;
}

h1 {
  color: #4c4b4b;
}

b {
  color: #4c4b4b;
}
h2 {
  color: #4c4b4b;
}

p {
  font-size: larger;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.socials {
  display: flex;
  flex-direction: row;
  a {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
.icon {
  width: 60%;
  margin: auto;
}

.about {
  a {
    text-decoration: none;
  }
  &__photo {
    border-radius: 5px;
    padding-bottom: 10px;
    max-height: 400px;
  }
}
.experience {
  color: #4c4b4b;
  margin-block-end: 0;
}

.tech_icon {
  width: 70px;
  padding: 10px;
}

.icons {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding: 20px;
}
.skills {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: double #b9b6b6 5px;
  border-radius: 5px;
  border-radius: 5px;
  margin: 50px auto;
}

.projects {
  display: flex;
  flex-direction: column;
}

.project {
  &__images-container {
    margin-top: 20px;
  }

  &__image {
    max-width: 300px;
    margin-top: 20px;
  }

  &__tech {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: 20px;
    border-bottom: solid #e1dede 1px;
    padding: 30px;
  }
  &__tech-name {
    display: none;
  }

  &__button {
    background-color: #4c4b4b;
    color: white;
    text-decoration: none;
    padding: 10px 15px;
    border-radius: 5px;
  }
  &__blurb {
    margin-top: 30px;
  }
}

@media only screen and (min-width: 600px) {
  .about {
    display: flex;
    flex-direction: row;
    align-items: center;

    &__info {
      margin-left: 50px;
      font-size: 18px;
    }
  }

  .icon {
    width: 30%;
  }

  .project {
    font-size: 18px;
    &__blurb {
      margin-left: 30px;
    }

    &__skill-badge {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &__tech-name {
      display: flex;
    }
  }
  .desktop-info {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
  }
}
